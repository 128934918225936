import React from "react";

const StartLogoIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.3"
        x="5.31332"
        width="39.0498"
        height="39.0498"
        rx="12"
        transform="rotate(7.82022 5.31332 0)"
        fill="white"
      />
      <rect
        x="3.98273"
        y="4.43533"
        width="35.4827"
        height="35.4827"
        rx="12"
        fill="white"
      />
      <path
        d="M12.8534 25.5032V19.0719L18.3975 15.7454L24.1635 19.0719L18.6193 22.1767V28.8297L12.8534 25.5032Z"
        fill="#222130"
      />
      <path
        d="M24.942 11.8661L30.5098 15.0847L30.615 21.5494L24.8494 24.8765L24.9362 18.5228L19.1763 15.1931L24.942 11.8661Z"
        fill="#222130"
      />
      <path
        d="M30.5027 29.202L24.9302 32.4125L19.2801 29.2692L19.2841 22.6125L24.742 25.8665L30.5067 22.5453L30.5027 29.202Z"
        fill="#222130"
      />
    </svg>
  );
};

export default StartLogoIcon;
