import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { authReducer } from "./auth/authReducer";
import { setKeyReducer } from "./auth/setKeyReducer";
import { categoryReducer } from "./category/categoryReducer";
import { profileReducer } from "./profile/profileReducer";
import { projectReducer } from "./project/projectReducer";
import { tasksReducer } from "./tasks/tasksReducer";

const rootReducer = combineReducers({
  setKey: setKeyReducer,
  auth: authReducer,
  category: categoryReducer,
  project: projectReducer,
  profile: profileReducer,
  tasks: tasksReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export { store };
