import axios from "axios";

import { colors } from "../configs/colors";

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

apiInstance.interceptors.request.use(function (config) {
  const tokenStr = localStorage.getItem("token");
  config.url = `${config.url}`;
  config.headers = { Authorization: `Bearer ${tokenStr}` };

  return config;
});

const api = {
  privacyPolicy: async () => {
    return apiInstance.get("attachment/privacy-policy");
  },

  termOfUse: async () => {
    return apiInstance.get("attachment/terms-of-use");
  },

  login: async (email, password) => {
    return apiInstance.post("login", {
      email,
      password,
    });
  },

  registration: async (email, password, password2) => {
    return apiInstance.post("registration", {
      email,
      password,
      password2,
    });
  },

  send: async (email) => {
    return apiInstance.post("password/send", {
      email,
    });
  },

  confirm: async (key, code) => {
    return apiInstance.post("password/confirm", {
      key,
      code,
    });
  },

  reset: async (key, password, password2) => {
    return apiInstance.post("password/reset", {
      key,
      password,
      password2,
    });
  },

  userUpdate: async (name, email, avatarId = null) => {
    return apiInstance.put("user/me", {
      avatarId,
      email,
      name,
    });
  },

  tasksList: async () => {
    return apiInstance.get("user/me/tasks");
  },

  taskCreate: async (
    title,
    projectId,
    performerId,
    deadline,
    importance = 0,
    description = null,
    color = `${colors.categoryBlue}`
  ) => {
    return apiInstance.post("task", {
      title,
      description,
      deadline,
      color,
      importance,
      projectId,
      performerId,
    });
  },

  taskGet: async (id) => {
    return apiInstance.get(`task/${id}`);
  },

  taskCount: async () => {
    return apiInstance.get("user/me/count/task");
  },

  taskCompleted: async (id, isCompleted = true) => {
    return apiInstance.put(`task/${id}/completed`, {
      isCompleted,
    });
  },
  taskNoCompleted: async (id, isCompleted = false) => {
    return apiInstance.put(`task/${id}/completed`, {
      isCompleted,
    });
  },

  subTaskCreate: async (
    title,
    deadline,
    performerId,
    taskId,
    description = null,
    color = `${colors.categoryBlue}`,
    importance = 0
  ) => {
    return apiInstance.post("sub-task", {
      title,
      description,
      deadline,
      color,
      importance,
      performerId,
      taskId,
    });
  },

  subTaskList: async (id) => {
    return apiInstance.get(`task/${id}/sub-tasks`);
  },

  subTaskCount: async () => {
    return apiInstance.get("user/me/count/sub-task");
  },

  subTaskGet: async (id) => {
    return apiInstance.get(`sub-task/${id}`);
  },

  subTaskCompleted: async (id, isCompleted = true) => {
    return apiInstance.put(`sub-task/${id}/completed`, {
      isCompleted,
    });
  },
  subTaskNoCompleted: async (id, isCompleted = false) => {
    return apiInstance.put(`sub-task/${id}/completed`, {
      isCompleted,
    });
  },

  changePassword: async (oldPassword, newPassword) => {
    return apiInstance.put("user/me/password", {
      oldPassword,
      newPassword,
    });
  },

  user: async () => {
    return apiInstance.get("user/me");
  },

  updateUser: async (avatarId, email, name) => {
    return apiInstance.put("user/me", {
      avatarId,
      email,
      name,
    });
  },
  createCategory: async (title, color) => {
    return apiInstance.post("category", {
      title,
      color,
    });
  },
  getCategory: async () => {
    return apiInstance.get("category");
  },
  categoryDelete: async (id) => {
    return apiInstance.delete(`category/${id}`);
  },
  updateCategory: async (title, color, id) => {
    return apiInstance.put(`category/${id}`, {
      title,
      color,
    });
  },

  createProject: async (title, categoryId) => {
    return apiInstance.post("project", {
      title,
      categoryId,
    });
  },
  getProject: async (id) => {
    return apiInstance.get(`category/${id}/projects`);
  },
  projectDelete: async (id) => {
    return apiInstance.delete(`project/${id}`);
  },
  updateProject: async (title, categoryId, id) => {
    return apiInstance.put(`project/${id}`, {
      title,
      categoryId,
    });
  },

  uploadImage: async (formData) => {
    return apiInstance.post("attachment/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  logout: async () => {
    return apiInstance.post("logout");
  },
};

export default api;
