import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Api from "api";
import { pathConfig } from "configs/router/pathConfig";

import { setToken, setError } from "./authAction";
import { setKeyAction } from "./setKeyReducer";

export const useAuthAction = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const key = useSelector((state) => state.setKey.key);

  const sendLoginData = (email, password) => {
    Api.login(email, password)

      .then((res) => {
        dispatch(setToken(res.data.accessToken));
        localStorage.setItem("token", res.data.accessToken);

        navigate(pathConfig.main, { replace: true });

        dispatch(setError({ isError: false, errorMessage: null }));
      })
      .catch(() =>
        dispatch(
          setError({
            isError: true,
            errorMessage: t("pages.login.statusError"),
          })
        )
      );
  };

  const sendRegistrationData = (data) => {
    Api.registration(data.email, data.password, data.password2)
      .then((res) => {
        dispatch(setToken(res.data.accessToken));
        localStorage.setItem("token", res.data.accessToken);
        navigate(pathConfig.main, { replace: true });
        dispatch(setError({ isError: false, errorMessage: null }));
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(
            setError({
              isError: true,
              errorMessage: t("pages.registration.statusPassword"),
            })
          );
        }
        if (error.response.status === 400) {
          dispatch(
            setError({
              isError: true,
              errorMessage: t("pages.registration.statusMail"),
            })
          );
        }
      });
  };

  const sendMail = (data) => {
    Api.send(data.email)
      .then((res) => {
        dispatch(setKeyAction(res.data.key));
        navigate(pathConfig.resetPasswordCode, { replace: true });
        dispatch(setError({ isError: false, errorMessage: null }));
      })
      .catch(() =>
        dispatch(
          setError({
            isError: true,
            errorMessage: t("pages.resetPasswordMail.statusError"),
          })
        )
      );
  };

  const sendCode = (data) => {
    Api.confirm(key, data.code)
      .then((res) => {
        dispatch(setKeyAction(res.data.key));
        dispatch(setError({ isError: false, errorMessage: null }));
        navigate(pathConfig.resetPassword, { replace: true });
      })
      .catch(() =>
        dispatch(
          setError({
            isError: true,
            errorMessage: t("pages.resetPasswordCode.statusError"),
          })
        )
      );
  };

  const sendNewPassword = (data) => {
    Api.reset(key, data.password, data.password2)
      .then(() => {
        dispatch(setError({ isError: false, errorMessage: null }));
        navigate(pathConfig.login, { replace: true });
      })
      .catch(() =>
        dispatch(
          setError({
            isError: true,
            errorMessage: t("pages.resetPassword.statusError"),
          })
        )
      );
  };
  return {
    sendLoginData,
    sendRegistrationData,
    sendMail,
    sendCode,
    sendNewPassword,
  };
};
