import {
  GET_COUNT_TASK,
  GET_TASK,
  TASKS_LIST,
  GET_SUBTASK_LIST,
  GET_CATEGORY,
  GET_COUNT_SUBTASK,
  GET_SUBTASK
} from "./tasksAction";


const initialState = {
  subTaskList: null,
  taskList: {
    veryUrgently: [],
    urgently: [],
    mayWait: [],
    notUrgently: []
  },
  taskGet: null,
  subTaskGet: null,
  listCategory: null,
  taskCount: null,
  subTaskCount: null
};

export const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        listCategory: action.payload
      };

    case TASKS_LIST:
      return {
        ...state,
        taskList: action.payload
      };

    case GET_TASK:
      return {
        ...state,
        taskGet: action.payload
      };

    case GET_COUNT_TASK:
      return {
        ...state,
        taskCount: action.payload
      };

    case GET_COUNT_SUBTASK:
      return {
        ...state,
        subTaskCount: action.payload
      };

    case GET_SUBTASK_LIST:
      return {
        ...state,
        subTaskList: action.payload
      };

    case GET_SUBTASK:
      return {
        ...state,
        subTaskGet: action.payload
      };

    default:
      return state;
  }
};