import React from "react";
import { Route, Routes } from "react-router-dom";

import { Background, LoginWrapper, Img } from "pages/Auth/Styled";
import BackgroundImg from "static/img/BackgroundImg.png";

import Login from "./Login/Login";
import Registration from "./Registration/Registration";
import ResetPassword from "./ResetPassword/ResetPassword/ResetPassword";
import ResetPasswordCode from "./ResetPassword/ResetPasswordCode/ResetPasswordCode";
import ResetPasswordMail from "./ResetPassword/ResetPasswordMail/ResetPasswordMail";

const Auth = () => {
  return (
    <LoginWrapper>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="resetPasswordMail" element={<ResetPasswordMail />} />
        <Route path="resetPasswordCode" element={<ResetPasswordCode />} />
        <Route path="resetPassword" element={<ResetPassword />} />
      </Routes>
      <Background>
        <Img src={BackgroundImg} alt="" />
      </Background>
    </LoginWrapper>
  );
};

export default Auth;
