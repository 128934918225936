import styled from "styled-components";

import { colors } from "configs/colors";
import StartBackgroundImg from "static/img/StartBackgroundImg.png";
import { makeTextStyles } from "utils/styles/makeTextStyles";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(${StartBackgroundImg});
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 840px;
  height: 330px;
  padding: 0 10px;
  margin: 200px auto;
`;

export const Navigate = styled.div`
  height: 100px;
  max-width: 1440px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: ${({ mt = 0 }) => `${mt}px`};
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

export const LogoTitle = styled.p`
  color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${makeTextStyles(24, 700)};
  font-style: normal;
  width: 227px;
  margin: 0;
  line-height: 28px;
  text-transform: uppercase;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 366px;
  justify-content: space-between;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  ${makeTextStyles(48, 500)};
  line-height: 67px;
  margin: 0;
`;

export const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  ${makeTextStyles(24, 400)};
  line-height: 34px;
  margin: 0;
`;
