export const SET_TITLE = "SET_TITLE";
export const GET_PROJECT = "GET_PROJECT";

export const setTitle = (payload) => {
  return {
    type: SET_TITLE,
    payload,
  };
};

export const getProject = (payload) => {
  return {
    type: GET_PROJECT,
    payload,
  };
};
