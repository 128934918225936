export const TASKS_LIST = "TASKS_LIST";
export const GET_TASK = "GET_TASK";
export const GET_SUBTASK = "GET_SUBTASK";
export const GET_COUNT_TASK = "GET_COUNT_TASK";
export const GET_COUNT_SUBTASK = "GET_COUNT_SUBTASK";
export const GET_SUBTASK_LIST = "GET_SUBTASK_LIST";
export const GET_CATEGORY = "GET_CATEGORY";

export const getCategory = (payload) => {
  return {
    type: GET_CATEGORY,
    payload
  };
};

export const TasksList = (payload) => {
  return {
    type: TASKS_LIST,
    payload
  };
};

export const getTask = (payload) => {
  return {
    type: GET_TASK,
    payload
  };
};

export const getSubTask = (payload) => {
  return {
    type: GET_SUBTASK,
    payload
  };
};

export const getCountTask = (payload) => {
  return {
    type: GET_COUNT_TASK,
    payload
  };
};

export const getCountSubtask = (payload) => {
  return {
    type: GET_COUNT_SUBTASK,
    payload
  };
};

export const getSubTaskList = (payload) => {
  return {
    type: GET_SUBTASK_LIST,
    payload
  };
};

