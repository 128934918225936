import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58198 0 0 3.58198 0 8C0 12.4185 3.58198 16 8 16C12.4185 16 16 12.419 16 8C16 3.58198 12.4185 0 8 0ZM8 14.5C4.41002 14.5 1.50002 11.59 1.50002 8C1.50002 4.41002 4.41002 1.50002 8 1.50002C11.59 1.50002 14.5 4.41002 14.5 8C14.5 11.59 11.59 14.5 8 14.5ZM8.0005 3.50848C7.42752 3.50848 6.99398 3.80751 6.99398 4.29049V8.72048C6.99398 9.20397 7.42746 9.50198 8.0005 9.50198C8.5595 9.50198 9.00703 9.1915 9.00703 8.72048V4.29049C9.00697 3.81897 8.5595 3.50848 8.0005 3.50848ZM8.0005 10.5C7.45001 10.5 7.00198 10.948 7.00198 11.499C7.00198 12.049 7.45001 12.497 8.0005 12.497C8.551 12.497 8.99852 12.049 8.99852 11.499C8.99847 10.948 8.551 10.5 8.0005 10.5Z"
        fill="#D90000"
      />
    </svg>
  );
};

export default ErrorIcon;
