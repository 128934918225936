import { Suspense } from "react";

import Router from "./configs/router/Router";

const App = () => {
  return (
    <Suspense fallback={<p> Loading...</p>}>
      <Router />
    </Suspense>
  );
};

export default App;
