import { SET_TITLE, SET_COLOR, GET_CATEGORY } from "./categoryAction";

const initialState = {
  title: null,
  color: null,
  listCategory: [],
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        listCategory: action.payload,
      };

    default:
      return state;
  }
};
