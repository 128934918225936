import { lazy } from "react";

import { pathConfig } from "configs/router/pathConfig";
//TODO заменить флаги protected
export const routeConfig = [
  {
    path: pathConfig.notFound,
    component: lazy(() => import("pages/NotFound")),
    protected: false,
  },
  {
    path: pathConfig.auth,
    component: lazy(() => import("pages/Auth/Auth")),
    protected: false,
  },
  {
    path: pathConfig.start,
    component: lazy(() => import("pages/Start")),
    protected: false,
  },
  {
    path: pathConfig.calendar,
    component: lazy(() => import("pages/Calendar")),
    protected: false,
  },
  {
    path: pathConfig.main,
    component: lazy(() => import("pages/Main")),
    protected: false,
  },
  {
    path: pathConfig.project,
    component: lazy(() => import("pages/Project")),
    protected: false,
  },
  {
    path: pathConfig.profile,
    component: lazy(() => import("pages/Profile")),
    protected: false,
  },
  {
    path: pathConfig.appoint,
    component: lazy(() => import("pages/ApPoint")),
    protected: false,
  },
];
