import React from "react";

import ErrorIcon from "components/Icons/ErrorIcon";
import {
  InputContainer,
  InputWrapper,
  MainInput,
} from "components/Input/Styled";
import { Error, ErrorWrap, LoginLabel } from "pages/Auth/Styled";

const Input = ({
  placeholder,
  register,
  width,
  id,
  value,
  error,
  type = "text",
  onChange,
  disabled,
  ref,
}) => {
  return (
    <InputContainer>
      <LoginLabel>
        {placeholder}
        <InputWrapper>
          <MainInput
            ref={ref}
            placeholder={placeholder}
            width={width}
            id={id}
            value={value}
            type={type}
            onChange={onChange}
            disabled={disabled}
            autocomplete="off"
            {...register}
          />
        </InputWrapper>
      </LoginLabel>
      <ErrorWrap>
        {error?.isError && (
          <Error>
            <ErrorIcon />
            {error?.errorMessage}
          </Error>
        )}
      </ErrorWrap>
    </InputContainer>
  );
};

export default Input;
