import React from "react";

const PasswordIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M12 14.5714C13.4202 14.5714 14.5714 13.4201 14.5714 12C14.5714 10.5798 13.4202 9.42856 12 9.42856C10.5799 9.42856 9.42859 10.5798 9.42859 12C9.42859 13.4201 10.5799 14.5714 12 14.5714Z"
          fill="black"
        />
        <path
          d="M21.1393 11.21C18.9078 8.55533 15.5299 6 12 6C8.4695 6 5.09077 8.55712 2.86072 11.21C2.47497 11.6687 2.47497 12.3346 2.86072 12.7933C3.42138 13.4602 4.59677 14.7477 6.16662 15.8727C10.1202 18.706 13.8711 18.7122 17.8334 15.8727C19.4033 14.7477 20.5787 13.4602 21.1393 12.7933C21.524 12.3355 21.526 11.6702 21.1393 11.21ZM12 8.00054C14.2412 8.00054 16.0644 9.79531 16.0644 12.0016C16.0644 14.2079 14.2412 16.0027 12 16.0027C9.75884 16.0027 7.9357 14.2079 7.9357 12.0016C7.9357 9.79531 9.75884 8.00054 12 8.00054Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default PasswordIcon;
