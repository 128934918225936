export const GET_PROFILE = "GET_PROFILE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const SET_ERROR_PASS = "SET_ERROR_PASS";

export const getProfile = (payload) => {
  return {
    type: GET_PROFILE,
    payload,
  };
};

export const uploadImage = (payload) => {
  return {
    type: UPLOAD_IMAGE,
    payload,
  };
};

export const setErrorPass = (payload) => {
  return {
    type: SET_ERROR_PASS,
    payload,
  };
};
