export const pathConfig = {
  notFound: "*",

  start: "/",

  auth: "/auth/*",
  login: "/auth/Login",
  resetPasswordMail: "/auth/resetPasswordMail",
  registration: "/auth/Registration",
  resetPasswordCode: "/auth/resetPasswordCode",
  resetPassword: "/auth/ResetPassword",

  calendar: "/calendar",
  main: "/main",
  project: "/main/:title/:id",
  profile: "/profile",
  appoint: "/appointed",
};
