import { GET_PROFILE, SET_ERROR_PASS, UPLOAD_IMAGE } from "./profileAction";

const initialState = {
  fileImage: null,
  userProfile: {
    name: null,
    email: null,
    avatar: null
  },
  errorPass: {
    isError: false,
    errorMessage: null,
  },
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        userProfile: {
          name: action.payload.name,
          email: action.payload.email,
          avatar: action.payload.avatar
        },
      };

    case UPLOAD_IMAGE:
      return {
        ...state,
        fileImage: action.payload
      };

    case SET_ERROR_PASS:
      return {
        ...state,
        errorPass: action.payload,
      };

    default:
      return state;
  }
}
