import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as yup from "yup";

import Button from "components/Button";
import ArrowIcon from "components/Icons/ArrowIcon";
import LogoIcon from "components/Icons/LogoIcon";
import Input from "components/Input";
import { pathConfig } from "configs/router/pathConfig";
import {
  Flex,
  Form,
  SubTitle,
  GoBack,
  GoBackText,
  FlexInstruction,
  InstructionText,
  ContainerResetPassword,
  Title,
} from "pages/Auth/Styled";
import { useAuthAction } from "store/auth/useAuthAction";

const ResetPasswordMail = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("errors.email"))
      .required(t("errors.emailRequired")),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { sendMail } = useAuthAction();
  const error = useSelector((state) => state.auth.error);

  const onSubmit = (data) => {
    reset();
    sendMail(data);
  };

  return (
    <ContainerResetPassword>
      <Flex>
        <Title>
          <LogoIcon />
          {t("pages.login.title")}
        </Title>
      </Flex>
      <GoBack to={pathConfig.login}>
        <ArrowIcon />
        <GoBackText>{t("pages.resetPasswordMail.goBack")}</GoBackText>
      </GoBack>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubTitle>{t("pages.resetPasswordMail.subtitle")}</SubTitle>

        <Input
          placeholder={t("pages.login.email")}
          register={register("email")}
          error={{
            isError: errors?.email || error.isError,
            errorMessage: errors?.email?.message || error.errorMessage,
          }}
        />
        <Button type="submit" width={366} kind="main" mt={16}>
          {t("pages.resetPasswordMail.continue")}
        </Button>
        <FlexInstruction>
          <InstructionText>
            {t("pages.resetPasswordMail.instruction")}
          </InstructionText>
        </FlexInstruction>
      </Form>
    </ContainerResetPassword>
  );
};

export default ResetPasswordMail;
