export const SET_TITLE = "SET_TITLE";
export const SET_COLOR = "SET_COLOR";
export const GET_CATEGORY = "GET_CATEGORY";

export const setTitle = (payload) => {
  return {
    type: SET_TITLE,
    payload,
  };
};

export const setColor = (payload) => {
  return {
    type: SET_COLOR,
    payload,
  };
};

export const getCategory = (payload) => {
  return {
    type: GET_CATEGORY,
    payload,
  };
};
