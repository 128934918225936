import { SET_TITLE, GET_PROJECT } from "./projectAction";

const initialState = {
  title: null,
  listProject: [],
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case GET_PROJECT:
      return {
        ...state,
        listProject: action.payload,
      };

    default:
      return state;
  }
};
