import React from "react";
import { Routes, Route } from "react-router-dom";

import { routeConfig } from "configs/router/routeConfig";
import Auth from "pages/Auth";
import NotFound from "pages/NotFound";
import Start from "pages/Start";

import { pathConfig } from "./pathConfig";

const Router = () => {
  const routes = routeConfig
    .map((route) => {
      const Component = route.component;

      if (localStorage.getItem("token")) {
        return (
          <Route key={route.path} path={route.path} element={<Component />} />
        );
      }
      return null;
    })
    .filter(Boolean);

  return (
    <Routes>
      <Route path={pathConfig.start} element={<Start />} />
      <Route path={pathConfig.auth} element={<Auth />} />
      {routes}
      <Route path={pathConfig.notFound} element={<NotFound />} />
    </Routes>
  );
};

export default Router;
