import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import Button from "components/Button";
import StartLogoIcon from "components/Icons/StartLogoIcon";
import { pathConfig } from "configs/router/pathConfig";

import {
  Background,
  ButtonWrapper,
  Container,
  Flex,
  LogoTitle,
  Navigate,
  SubTitle,
  Title,
} from "./Styled";

const Start = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Background>
      <Navigate>
        <Flex>
          <LogoTitle>
            <StartLogoIcon />
            {t("pages.login.title")}
          </LogoTitle>
        </Flex>
        <ButtonWrapper>
          <Button
            type="button"
            width={175}
            kind="secondary"
            onClick={() => navigate(pathConfig.login, { replace: true })}
          >
            {t("pages.start.comeIn")}
          </Button>
          <Button
            type="button"
            width={175}
            kind="main"
            onClick={() => navigate(pathConfig.registration, { replace: true })}
          >
            {t("pages.start.registration")}
          </Button>
        </ButtonWrapper>
      </Navigate>
      <Container>
        <Title>{t("pages.start.title")}</Title>
        <SubTitle>{t("pages.start.subtitle")}</SubTitle>
        <Button
          type="button"
          width={366}
          kind="secondary"
          onClick={() => navigate(pathConfig.registration, { replace: true })}
        >
          {t("pages.start.registerOnSelf")}
        </Button>
      </Container>
    </Background>
  );
};

export default Start;
