export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  acent: "#007AFF",
  gray: "#979592",
  coldGray: "#9A99A2",
  lightGrey: "#CDCDCD",
  logoColor: "#222130",
  text: "#3D3D3D",
  buttonActive: "#0a082c",
  buttonHover: "#1a2059",
  secondary: "#D8D8D8",
  chooseBackground: "#F8F8F8",
  label: "#A9A9A9",
  lightAcent: "#E8F3FF",
  cardsStroke: "#DFDFDF",
  cardsSecondaryText: "#606060",
  cardsBackground: "#F5F5F5",
  darkRed: "#D90000",
  lightRed: "#FFE2E2",
  periodRed: "#FF5050",
  periodOrange: "#FFA114",
  periodYellow: "#FFC700",
  periodGreen: "#66CC00",
  categoryBlue: "#3787FF",
  categoryViolet: "#BF37FF",
  categoryRed: "#FF3939",
  categoryOrange: "#FF8125",
  categoryYellow: "#FFC700",
  categoryLightGreen: "#BDEC00",
  categoryGreen: "#00E532",
  categoryFucsia: "#FF00D6",
  categoryPink: "#FF00B8",
  categoryTiffany: "#00E7D9",
  categoryBackgroundBlue: "#EBF3FF",
  categoryBackgroundViolet: "#F5E9FB",
  categoryBackgroundRed: "#FFEBEB",
  categoryBackgroundOrange: "#FEF0E5",
  categoryBackgroundYellow: "#FFF9E5",
  categoryBackgroundLightGreen: "#F6F8E8",
  categoryBackgroundGreen: "#E5F9EA",
  categoryBackgroundFucsia: "#FFE7FE",
  categoryBackgroundPink: "#FDE5F3",
  categoryBackgroundTiffany: "#E5F8F7",
};
